import cryptoJS from 'crypto-js/crypto-js'
import _ from 'lodash'
import { getToken } from '@/utils/auth'
import axios from 'axios'

/**
 * 对象深拷贝
 */
export const deepClone = (data) => {
  return _.cloneDeep(data)
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a')
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeTranslate(data, id, pid) {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 树形数据转换 推荐关系用
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeRefereeTranslate(data) {
  var res = []
  var temp = {}
  var id = 'username'
  var pid = 'referee'
  for (var i = 0; i < data.length; i++) {
    data[i]['id'] = i
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * AES加密
 * @param pwd
 * @returns {string}
 */
export function cryptoAes(pwd) {
  if (!pwd) {
    return ''
  }
  let p = cryptoJS.enc.Utf8.parse(pwd)
  let keys = cryptoJS.enc.Utf8.parse('yitupwd220607abc')
  const iv = cryptoJS.enc.Utf8.parse('yitupwd220607abc')
  // 开始加密
  let encryptorStr = cryptoJS.AES.encrypt(p, keys, {
    iv: iv,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7
  })
  return encryptorStr.toString()
}

export function decrypt(pwd) {
  if (!pwd) {
    return ''
  }
  let p = cryptoJS.enc.Utf8.parse(pwd)
  let keys = cryptoJS.enc.Utf8.parse('yitupwd220607abc')
  const iv = cryptoJS.enc.Utf8.parse('yitupwd220607abc')
  const decrypt = cryptoJS.AES.decrypt(p, keys, {
    iv,
    mode: cryptoJS.mode.CBC,
    padding: cryptoJS.pad.Pkcs7
  }).toString(cryptoJS.enc.Utf8)
  return decrypt
}

export function axiosDownFile(url, data = null) {
  if (!url) return
  axios({
    method: 'post',
    url: url,
    responseType: 'blob',
    data: data,
    headers: { Authorization: getToken() }
  }).then((res) => {
    let blob = new Blob([res.data], {
      type: 'application/vnd.ms-excel;charset=utf-8'
    }) // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
    let fileName = decodeURI(res.headers['content-disposition']) // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
    if (fileName) {
      // 根据后端返回的数据处理文件名称
      fileName = fileName.substring(fileName.indexOf('=') + 1)
    }
    const link = document.createElement('a') // 创建一个a标签
    link.download = fileName // 设置a标签的下载属性
    link.style.display = 'none' // 将a标签设置为隐藏
    link.href = URL.createObjectURL(blob) // 把之前处理好的地址赋给a标签的href
    document.body.appendChild(link) // 将a标签添加到body中
    link.click() // 执行a标签的点击方法
    URL.revokeObjectURL(link.href) // 下载完成释放URL 对象
    document.body.removeChild(link) // 移除a标签
  })
}

export function axiosDownFileForPdf(url, data = null) {
  if (!url) return;
  axios({
    method: 'post',
    url: url,
    responseType: 'blob',
    data: data,
    headers: { Authorization: getToken() }
  }).then((res) => {
    let blob = new Blob([res.data], { type: 'application/pdf;charset=utf-8' });
    let fileName = 'download.pdf';

    // 获取文件名
    const disposition = res.headers['content-disposition'];
    if (disposition && disposition.includes('filename=')) {
      fileName = decodeURI(disposition.split('filename=')[1]);
    }

    const link = document.createElement('a');
    link.download = fileName;
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }).catch((error) => {
    console.error('Error downloading file:', error);
  });
}
