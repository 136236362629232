import Cookies from 'js-cookie'

const TokenKey = 'Tiger-Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const userKey = 'userInfo';

export function getUserInfoLocation() {
  return JSON.parse(localStorage.getItem(userKey));
}
export function setUserInfoLocation(userInfo) {
  localStorage.setItem(userKey, userInfo);
}

export function removeUserInfoLocation() {
  localStorage.removeItem(userKey);
}
