<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { setDocumentLang, setDocumentTitle } from '@/util/i18n/document'
export default {
  mounted() {
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentLang(newLocale)
        setDocumentTitle(this.$t('sys.sysname'))
      },
      { immediate: true }
    )
  }
}
</script>
<style>
body .el-table th.gutter {
  display: table-cell !important;
}

body .el-table colgroup.gutter {
  display: table-cell !important;
}
.el-input__inner {
  border-radius: 0!important;
}
</style>

