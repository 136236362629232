import Vue from "vue";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/element-#7073FF/index.css";
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n 中文
import enLocale from 'element-ui/lib/locale/lang/en' // lang i18n 英文

import animate from "animate.css";

import "@/styles/index.scss"; // global css
import "@/utils/dialog.js";
import App from "./App";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import {
  localizeAsset,
  localizeParam,
  localizeReturnValue
} from './util/i18n/general'
import Vab from "@/utils/vab";

import "@/icons"; // icon
import "@/permission"; // permission control
import * as echarts from "echarts";
import "echarts-gl";
Vue.prototype.$echarts = echarts;
import AMap from "vue-amap"; //高德地图

// 设置ElementUI的locale
let locale;

// 国际化
switch (i18n.locale) {
  case "zh":
    locale = zhLocale;
    break;
  default:
    locale = enLocale;
}

Vue.mixin({
  methods: {
    localizeParam: function (item, param) {
      return localizeParam(item, param)
    },
    localizeAsset: function (assetName) {
      return localizeAsset(assetName)
    },
    localizeReturnValue: function (data) {
      return localizeReturnValue(data)
    }
  }
})


Vue.use(ElementUI, {
  locale
})

Vue.use(animate);
Vue.use(AMap); //高德地图
Vue.use(Vab);

import Print from "@/api/print";
Vue.use(Print); // 注册

import axios from "axios";
Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.prototype.isAuth = (key) => {
  return (store.getters.permissions || "[]").indexOf(key) !== -1 || false;
};

Vue.prototype.isIndexAuth = (key) => {
  return (store.getters.indexPermissions || "[]").indexOf(key) !== -1 || false;
};

AMap.initAMapApiLoader({
  key: "b21fef78833005fc7e4e977de9cb1497",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.Geolocation",
    "AMap.Geocoder",
    "AMap.AMapManager",
    "AMap.Marker",
  ],
  uiVersion: "1.0.11", // 版本号
}); //高德地图
// new Vue({
//   el: '#app',
//   router,
//   store,
//   template: '<App/>',
//   components: { App }
// })

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
