import request from '@/utils/request'
export function userInfo(params) {
    return request({
        url: "/c/user/getUserInfo",
        method: "get",
        params,
    });
}

export function updateEmail(data) {
    return request({
        url: "/c/user/updateEmail",
        method: "post",
        data,
    });
}

//获取验证码
export function gainCode(params) {
    return request({
        url: `/c/user/sendEmail?${params}`,
        method: 'post',
    })
}

//验证验证码
export function Proving(params) {
    return request({
        url: "/c/user/checkEmailCode",
        method: 'post',
        params: params
    })
}