import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // 验权

const whiteList = ['/login', '/forget', '/reset', '/serviceAgreement', '/personalPrivacy'] // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (getToken()) {
    if (to.path === '/login' || to.path === '/forget' || to.path === '/reset') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (!store.getters.isGetMenu) {
        // 判断当前用户是否已拉权限信息
        store
          .dispatch('setTokenVuex')
          .then(() => {
            store.dispatch('GenerateRoutes').then((res) => {
              // 获取路由信息
              if (!res) {
                router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
                next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
              } else {
                store.dispatch('FedLogOut').then(() => {
                  // Message.error(err || 'Verification failed, please login again')
                  next({ path: '/' })
                })
              }
            })
          })
          .catch((err) => {
            console.log(err)
            store.dispatch('FedLogOut').then(() => {
              Message.error(err || 'Verification failed, please login again')
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
