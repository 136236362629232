import request from '@/utils/request'

const url = '/c/resource'

/**
 * 获取用户资源信息
 * @returns
 */
export function getNavApi() {
  return request({
    url: `${url}/getNav`,
    method: 'get'
  })
}

/**
 * 获取资源列表信息
 * @returns
 */
export function getTreeApi() {
  return request({
    url: `${url}/getTree`,
    method: 'get'
  })
}

/**
 * 获取导航列表信息
 * @returns
 */
export function getNavigationListApi() {
  return request({
    url: `${url}/getNavigationList`,
    method: 'get'
  })
}
