<template>
  <div class="main-el-menu">
    <el-menu class="navbar" mode="horizontal">
      <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>
      <breadcrumb></breadcrumb>
      <div class="right-menu">

        <!-- 隐藏功能 -->
        <!-- <template>
          <router-link target="_blank" :to="{ path: '/datav' }" v-if="hasKanBan == '1'">
            <img style="height: 22px; margin-right: 30px; margin-top: 18px" src="@/assets/index/datav.png" />
          </router-link>
            <img style="width: 28px; height: 28px; margin-right: 30px" src="@/assets/index/gwc.png" />
          <img style="
              width: 11px;
              height: 16px;
              margin-right: 15px;
              cursor: pointer;
            " @click="handleDeviceBoot" src="@/assets/index/sb.png" />
        </template>
<template>
          <a @click="handleDeviceBoot">
            <el-input v-model="device.deviceName" placeholder="未连接设备" size="small" readonly style="
                min-width: 80px;
                max-width: 200px;
                width: auto;
                color: #171616;
              " maxlength="30"></el-input>
          </a>
        </template> -->
        <!-- <el-badge class="item" style="">
          <img style="width: 15px; height: 17px; margin-left: 20px" src="@/assets/index/tz.png" />
        </el-badge> -->
        <!-- <el-badge class="item" style="">
          <el-tooltip :content="$t('menu.userManual')" placement="left">
            <img style="width: 20px; height: 20px; cursor: pointer; margin-top: 21px; margin-left: 20px"
              src="@/assets/index/help.png" @click="goToHelpLink" />
          </el-tooltip>
        </el-badge> -->
        <div class="help-div">
          <div style="cursor: pointer;" @click="goToHelpLink">{{ $t('menu.userManual') }}</div>
          <img style="width: 20px; height: 20px; cursor: pointer; margin-left: 20px" src="@/assets/index/help.png"
            @click="goToHelpLink" />
        </div>
        <div style="margin-left: 20px">
          <LocaleSwitcher />
        </div>
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <!-- <img class="user-avatar" src="../../../assets/img/avatar.png" /> -->
            <div>
              <span style="color: #999">{{ setUserName(vo.nickName) }}</span>
            </div>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <el-dropdown-menu class="user-dropdown" slot="dropdown">
            <el-dropdown-item>{{ $t('accounts.common.account') }}：{{ vo.userName }}</el-dropdown-item>

            <el-dropdown-item>{{ $t('accounts.common.email') }}：{{ vo.email }}</el-dropdown-item>
            <el-dropdown-item>{{ $t('accounts.common.role') }}：{{ vo.role }}</el-dropdown-item>
            <el-dropdown-item>{{ $t('accounts.common.factory') }}：{{ vo.factoryName }}</el-dropdown-item>
            <el-dropdown-item>{{ $t('accounts.common.brand') }}：{{ vo.brandName }}</el-dropdown-item>
            <el-dropdown-item>{{ $t('accounts.common.category') }}：{{ vo.category }}</el-dropdown-item>
            <el-dropdown-item>
              <el-button type="primary" size="small" @click="updatepasswordHandle(vo)">{{
                $t('accounts.common.changePassword')
              }}</el-button>
              <el-button type="primary" size="small" @click="updatepemailHandle(vo)">{{
                $t('accounts.common.changeEmail')
              }}</el-button>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <span @click="logout" style="display: block">{{ $t('accounts.common.logout') }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-menu>
    <update-password v-if="updatePasswordVisible" ref="updatePassword"></update-password>
    <update-email v-if="updateEmailVisible" ref="updateEmail"></update-email>
    <device-boot v-if="deviceBootShow" ref="deviceBoot" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { getUserInfoApi } from '@/api/modules/user'
import UpdatePassword from './updatepassword'
import UpdateEmail from './updateEmail'
import { selectBrandInfo } from '@/api'
import LocaleSwitcher from '@/components/LocaleSwitcher'

export default {
  data() {
    return {
      websock: null,
      deviceBootShow: false,
      hasKanBan: '',
      vo: {
        userId: '',
        userName: ''
      },
      updatePasswordVisible: false,
      updateEmailVisible: false,
      deviceList: [],
      device: {
        deviceId: '',
        deviceName: ''
      }
    }
  },
  components: {
    Breadcrumb,
    Hamburger,
    UpdatePassword,
    UpdateEmail,
    LocaleSwitcher,
    DeviceBoot: () => import('@/components/DeviceBoot')
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'userDevice']),
    setUserName() {
      //text就是所传参数
      return function (text = '') {
        if (text.length > 14) {
          return text.slice(0, 15) + '...'
        } else {
          return text
        }
      }
    }
  },
  watch: {
    'userDevice.id'(newval, olval) {
      this.deviceList = []
      this.device.deviceId = newval
      this.device.deviceName = this.userDevice.deviceName
      this.deviceList.push({
        deviceName: this.userDevice.deviceName,
        deviceId: newval
      })
      if (this.websock) {
        this.websock.close()
      }
      this.initWebSocket()
    }
    // userDevice(newVal, oldVal) {
    //   // 数据变化后，调用b组件内的函数
    //   console.log(newVal, oldVal)
    // }
  },
  created() {
    const userDevice = this.$store.getters.userDevice
    if (userDevice && userDevice.id) {
      this.initWebSocket()
      this.device.deviceId = userDevice.id
      this.device.deviceName = userDevice.deviceName
      this.deviceList.push({
        deviceName: userDevice.deviceName,
        deviceId: userDevice.id
      })
    }
    this.getNameAndRole()
    // this.selectBrandInfo();
  },
  methods: {
    goToHelpLink() {
      window.open('https://udpx6c0dzu.feishu.cn/wiki/QQxrwUquni5yh3kCnbmcEPTPnKc', '_blank');
    },
    handleDeviceBoot() {
      this.deviceBootShow = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.deviceBoot.init()
        })
      })
    },
    toggleSideBar() {
      this.$store.dispatch('ToggleSideBar')
    },
    logout() {
      if (this.websock) {
        this.websock.close()
      }
      const userDevice = this.$store.getters.userDevice
      userDevice.id = ''
      userDevice.deviceName = ''
      this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        console.log(this.$store.getters.userDevice)
      })
      this.device.deviceId = ''
      this.device.deviceName = ''
      sessionStorage.removeItem('testKey')
      this.$store.dispatch('LogOut').then(() => {
        sessionStorage.removeItem('nameAndRoleSysOrder')
        console.log('登出')
        location.reload() // 为了重新实例化vue-router对象 避免bug
        console.log('完成')
      })
      //Cookies.set(deviceId, '')
    },
    //导航栏下拉框
    changeValue(val) {
      //Cookies.set(deviceId, val)
      this.handleDeviceBoot()
    },
    //获取已授权设备
    // getselectDevice() {
    //   let params = {
    //     mac: ""
    //   }
    //   selectDevice(params).then(({ data }) => {
    //     this.$nextTick(() => {
    //       if (data && data.code === 0) {
    //         this.deviceList = data.deviceList;
    //         if (data.deviceList.length > 0) {
    //           this.device.deviceId = data.deviceList[0].deviceId;
    //         }
    //         Cookies.set(deviceId, this.device.deviceId)
    //       } else {
    //         this.deviceList = []
    //       }
    //     })
    //   })
    // },
    //修改密码
    updatepasswordHandle(vo) {
      this.updatePasswordVisible = true
      this.$nextTick(() => {
        this.$refs.updatePassword.init(vo)
      })
    },
    //修改邮箱
    updatepemailHandle(vo) {
      this.updateEmailVisible = true
      this.$nextTick(() => {
        this.$refs.updateEmail.init(vo)
      })
    },
    //获取用户账号和用户角色
    getNameAndRole() {
      let params = {}
      getUserInfoApi(params).then((response) => {
        sessionStorage.setItem('nameAndRoleSysOrder', response)
        this.vo = response
        sessionStorage.setItem('userId', response.id)
        sessionStorage.setItem(
          'factoryId',
          response.factoryId ? response.factoryId : ''
        )
      })
    },
    async selectBrandInfo() {
      const { data } = await selectBrandInfo()
      if (data && data.code === 0) {
        this.hasKanBan = data.data.hasKanBan
        sessionStorage.setItem(
          'BrandInfo',
          data.data.brandId ? data.data.brandId : ''
        )
      }
    },
    initWebSocket() {
      //初始化weosocket
      //ws地址
      var id = this.$store.getters.userDevice.id
      if (null == id || '' == id) {
        if (this.websock) {
          this.websock.close()
        }
        return
      }
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/device_state_' + id
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      console.log(e)
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      var code = retData.code
      console.log(code)
      var msg = retData.msg
      console.log(msg)
      var data = retData.data
      console.log(data)
      if (code == 1) {
        this.websock.close()
        const userDevice = this.$store.getters.userDevice
        userDevice.id = ''
        userDevice.deviceName = ''
        this.device.deviceId = ''
        this.device.deviceName = ''
        this.$store.dispatch('SetUserDevice', userDevice).then(() => {
          console.log(this.$store.getters.userDevice)
        })
        this.deviceBootShow = false
        this.$message.error('设备断开连接')
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror() {
      return this.$message.error('WebSocket连接失败')
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      console.log('connection closed (' + e.code + ')')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;

  .right-menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // float: right;
    // height: 100%;
    // line-height: 50px;
    // width: 20%;
    // margin-right: 150px;
  }

  .warning {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    font-size: 32px;
  }

  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }

  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }

  .avatar-container {
    height: 50px;
    display: inline-block;
    margin-right: 45px;
    margin-left: 30px;

    // position: absolute;
    // right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-items: center;

      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        margin-right: 10px;
      }

      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        font-size: 12px;
      }
    }
  }
}
</style>

<style lang="scss">
.main-el-menu {
  .navbar.el-menu--horizontal.el-menu {
    display: flex;
  }

  .el-badge__content.is-fixed {
    top: 13px;
  }

  .el-input--suffix .el-input__inner {
    background: #edeef2;
    border-color: #ccc;
    height: 32px;
    width: 100%;
    border-radius: 0;
  }
}

.help-div {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: center;
  /* 水平居中 */
  height: 100%;
  /* 父元素高度需要设置，否则无法完全居中 */
}
</style>
