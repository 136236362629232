const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  devicedata: (state) => state.user.devicedata,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  permissions: (state) => state.permission.permissions,
  indexPermissions: (state) => state.permission.indexPermissions,
  userDevice: (state) => state.permission.userDevice,
  isGetMenu: (state) => state.permission.isGetMenu
}
export default getters
