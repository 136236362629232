import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout'
import i18n from '@/i18n'

/** note: submenu only apppear when children.length>=1
 *   detail see  https://panjiachen.github.io/vue-element-admin-site/#/router-and-nav?id=sidebar
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  {
    path: '/datav',
    component: () => import('@/views/homePage/datav'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/forget',
    component: () => import('@/views/forget/index'),
    hidden: true
  },
  {
    path: '/reset',
    component: () => import('@/views/reset/index'),
    hidden: true
  },
  {
    path: '/serviceAgreement',
    component: () => import('@/views/serviceAgreement/index'),
    hidden: true
  },
  {
    path: '/personalPrivacy',
    component: () => import('@/views/personalPrivacy/index'),
    hidden: true
  },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'homePage',
  //   children: [
  //     {
  //       path: 'homePage',
  //       component: () => import('@/views/homePage/admin/index'),
  //       name: '首 页',
  //       meta: { title: '首 页', icon: 'example', noCache: true }
  //     }
  //   ]
  // }

  {
    path: '',
    component: Layout,
    redirect: 'search',
    children: [
      {
        path: 'search',
        component: () => import('@/views/search/product/index'),
        name: 'home',
        meta: { title: i18n.t('menu.home'), icon: 'example', noCache: true }
      }
    ]
  }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export const asyncRouterMap = [{ path: '*', redirect: '/404', hidden: true }]
