<template>
  <el-dialog :title="!dataForm.id ? this.$t('accounts.common.changeEmail') : this.$t('accounts.common.changeEmail')"
    :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" size="small" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
      label-width="120px" v-loading="dataLoading" style="width:100%;" element-loading-text=""
      element-loading-spinner="el-icon-loading">
      <el-form-item :label="$t('accounts.common.username')" prop="userName">
        <el-input v-model="dataForm.userName" :placeholder="$t('accounts.common.username')" disabled
          style="width:90%;"></el-input>
      </el-form-item>
      <el-form-item :label="$t('accounts.common.changeEmail.newEmail')" prop="email"
        :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.email" type="input" :placeholder="$t('accounts.common.changeEmail.newEmail')"
          style="width:75%;"></el-input>
        <el-button size="small" type="primary" @click="getCode" :disabled="value" style="margin-left: 10px;">
          {{ typeof text == "number" ? text + second : text }}
        </el-button>
      </el-form-item>
      <el-form-item :label="$t('login.login.captcha')" prop="code" :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.code" type="input" :placeholder="$t('login.login.enterCaptcha')"
          style="width:90%;"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">{{ $t('common.buttons.cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" size="small" :disabled="dataLoading">{{
        $t('common.buttons.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { userInfo, updateEmail, setSendEmail } from "@/api/sys";
import { isEmail } from "@/utils/validate";
export default {
  data() {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error("Wrong email format"));
      } else {
        callback();
      }
    };
    return {
      text: this.$t('accounts.common.changeEmail.getVerificationCode'),
      value: false,
      second: "S后重试",
      visible: false,
      dataLoading: false,
      email: '',
      dataForm: {
        id: 0,
        userName: "",
        email: "",
        code: "",
      },
      dataRule: {
        code: [{ required: true, message: this.$t('login.login.validateCaptcha'), trigger: "blur" }],
        email: [
          { required: true, message: this.$t('login.forgotPassword.emailNotEmpty'), trigger: "blur" },
          { validator: validateEmail, trigger: "blur" }
        ]
      }
    }
  },
  methods: {
    //根据用户邮箱发送验证码
    getCode() {
      localStorage.setItem("email", this.dataForm.email);
      var params = {
        email: this.dataForm.email
      }
      setSendEmail(params).then(({ data }) => {
        console.log(data);
        let codeData = data;
        if (codeData.code === 0) {
          this.$message({
            showClose: true,
            message: this.$t('sys.notifcations.success'),
            type: "success",
          });
        } else {
          this.$message.error(data.msg)
          this.dataLoading = false;
        }
      });
    },
    init(id) {
      this.dataForm.id = id;
      this.visible = true
      this.$nextTick(() => {
        this.dataForm.userName = '';
        if (this.dataForm.id) {
          var params = {
            userId: this.dataForm.id,
          };
          userInfo(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm.userName = data.user.userName;
              this.dataForm.email = '';
              this.dataForm.code = '';
              this.value = false;
              this.text = this.$t('login.forgotPassword.getVerificationCode');
            }
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true;
          var params = {
            userId: this.dataForm.id || undefined,
            email: this.dataForm.email,
            code: this.dataForm.code,
          }
          updateEmail(params).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: this.$t('physicalColors.prompt.action'),
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.dataLoading = false;
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
              this.dataLoading = false;
            }
          })
        }
      })
    }
  }
}
</script>
