import request from "@/utils/request";

const url = "/c/user/";

/**
 * 获取登录用户信息
 * @returns
 */
export function getUserInfoApi() {
  return request({
    url: `${url}getInfo`,
    method: "get",
  });
}

/**
 * 修改密码
 * @returns
 */
export function updateUserPwdApi(data) {
  return request({
    url: `${url}updatePwd`,
    method: "put",
    data,
  });
}

export function updateInitializePwdApi(data) {
  return request({
    url: `${url}updateInitializePwd`,
    method: "put",
    data,
  });
}


/**
 * 获取用户列表信息
 * @returns
 */
export function queryUserApi(data) {
  return request({
    url: `${url}query`,
    method: "post",
    data,
  });
}

/**
 * 创建/编辑用户
 * @returns
 */
export function addOrUpdateUserApi(data) {
  return request({
    url: `${url}${!data.id ? 'add' : 'update'}`,
    method: "put",
    data,
  });
}

/**
 * 根据用户ID获取用户信息
 * @returns
 */
export function getUserApi(id) {
  return request({
    url: `${url}get/${id}`,
    method: "get",
  });
}

/**
 * 删除用户
 * @returns
 */
export function deleteUserApi(id) {
  return request({
    url: `${url}delete/${id}`,
    method: "delete",
  });
}