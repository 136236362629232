import { getNavApi } from '@/api/modules/resource'
import { asyncRouterMap, constantRouterMap } from '@/router'
import Layout from '@/views/layout/Layout'
import i18n from '@/i18n'

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
// function hasPermission(roles, route) {
//   if (route.meta && route.meta.roles) {
//     return roles.some(role => route.meta.roles.indexOf(role) >= 0)
//   } else {
//     return true
//   }
// }

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
// function filterAsyncRouter(asyncRouterMap, roles) {
//   const accessedRouters = asyncRouterMap.filter(route => {
//     if (hasPermission(roles, route)) {
//       if (route.children && route.children.length) {
//         route.children = filterAsyncRouter(route.children, roles)
//       }
//       return true
//     }
//     return false
//   })
//   return accessedRouters
// }

const permission = {
  state: {
    // 是否获取了菜单
    isGetMenu: false,
    routers: constantRouterMap,
    addRouters: [],
    permissions: [],
    indexPermissions: [],
    userDevice: null
  },
  mutations: {
    SET_IS_GET_MENU: (state, isGetMenu) => {
      state.isGetMenu = isGetMenu
    },
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_INDEX_PERMISSIONS: (state, indexPermissions) => {
      state.indexPermissions = indexPermissions
    },
    SET_USER_DEVICE: (state, userDevice) => {
      state.userDevice = userDevice
    }
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve, reject) => {
        getNavApi()
          .then(({ menuList, permissions, userDevice }) => {
            const vData = menuList
            const newDatas = vData.map((v) => {
              const newRouters = {
                path: '',
                component: Layout,
                name: v.url,
                meta: {
                  title: i18n.t(v.resourceName),
                  icon: v.icon
                },
                children:
                  v.childCategoryList === null
                    ? []
                    : v.childCategoryList.map((res) => {
                      return {
                        path: `${res.url}`,
                        name: res.url,
                        component: loadView(`${res.url}`),
                        // component: () => import(`@/views/${res.url}`),
                        meta: { title: i18n.t(res.resourceName) }
                      }
                    })
              }
              return newRouters
            })
            const accessedRouters = [...asyncRouterMap, ...newDatas]
            commit('SET_ROUTERS', accessedRouters)
            commit('SET_PERMISSIONS', permissions)
            commit('SET_IS_GET_MENU', true)
            commit('SET_USER_DEVICE', userDevice)
            commit('SET_INDEX_PERMISSIONS', [])
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    SetUserDevice({ commit }, userDevice) {
      commit('SET_USER_DEVICE', userDevice)
    }
  }
}

/**
 * 路由懒加载
 */
export const loadView = (view) => {
  // webpack4中动态import不支持变量方式
  return (resolve, reject) => require([`@/views/${view}`], resolve)
}

export default permission